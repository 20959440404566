<template>
  <div class="entrust-list">
    <b-overlay :show="formBusy">
      <b-pagination   v-model="articlesMeta.current_page"
                      :total-rows="articlesMeta.total"
                      :per-page="articlesMeta.per_page"  @change="onChangePage"  />
      <b-row>
        <b-col sm="12" md="3" v-for="history in subscribeMessageHistories" :key="history.id">
          <portlet class="direction-rtl" body-class="flex-column" body-fluid head-size="sm" :title="history.stock.title">
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact" v-for="(template_keyword, index) in history.stock.template_keywords">
                        <span class="kt-widget__label">{{ template_keyword.title }}: </span>
                        <span class="kt-widget__data direction-rtl">{{ history.message.data[template_keyword.name].value }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تاپشۇرۇپ ئالغانلار</span>
                        <span class="kt-widget__data direction-ltr">{{ history.count }} نەپەر</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!subscribeMessageHistories.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، مەزكۇر قېلىپنى ئىشلىتىپ ئۇچۇر يوللاش خاتىرىسى يوق ئىكەن." />
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  // import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import subscribeMessageStockMixin from "@m@/subscribeMessage.stock.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, NoResult },
    mixins: [ formBusyMixin, sweetAlertMixin, subscribeMessageStockMixin ],
    computed: {
      modelId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    created() {
      this.getSubscribeMessageHistory(this.modelId)
        /*.then(response=>{
          let { data } = response.data;
          if ( data.length ){
            let {template_keywords: keywords = []} = data[0].stock;
            // this.formatTemplateKeywords(keywords);
            this.template_keywords = keywords;
          }
        })*/
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش', route: {name: 'subscribe_message.home'}});
      BreadCrumbs.addBreadCrumb({ title: "ئۇچۇر يوللاش خاتىرىسى" });
    },
    data(){
      return {
        formBusy: false,
        template_keywords: []
      };
    },
    methods: {
      formatTemplateKeywords(keys = []){
        keys.forEach( (item, index)=> {
          let { name } = item;
          this.$set(this.formData, name, null);
        })
      },
      onChangePage(e){
        let { page } = e;
        this.page = page;
        this.getSubscribeMessageHistory(this.modelId, page);
      },
      onRefresh(title){
        this.page = 1;
        this.getSubscribeMessageHistory(this.modelId, 1);
      },
    }
  }
</script>

<style>
</style>
