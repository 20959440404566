<template>
  <div class="subscribe-message">
    <b-overlay :show="formBusy">
      <portlet body-fit>
        <template slot="body">
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">مۇشتەرى ئەزا سانى</h4>
                    <span class="kt-widget24__desc">مۇشتەرى ئۇچۇرغا قوشۇلغان بارلىق ئەزا سانى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ subscribeMessageStocks.total_subscribe_user }} نەپەر</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="((subscribeMessageStocks.total_subscribe_user - subscribeMessageStocks.total_subscribe_unique_user) / subscribeMessageStocks.total_subscribe_user) * 100" variant="primary"></b-progress-bar>
                  <b-progress-bar :value="(subscribeMessageStocks.total_subscribe_unique_user / subscribeMessageStocks.total_subscribe_user ) * 100" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تەكرارلانمىغان ئەزا سانى</span>
                  <span class="kt-widget24__number">{{ subscribeMessageStocks.total_subscribe_unique_user }} نەپەر</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">UNKNOWN</h4>
                    <span class="kt-widget24__desc">---</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">...</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar value="0" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">unknown</span>
                  <span class="kt-widget24__number">...</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">قېلىپ يېڭىلاش</h4>
                    <span class="kt-widget24__desc">قېلىپ ئۇچۇرىنى ماسقەدەملەش</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger"><b-button @click="onClickUpdateStock" size="sm" variant="danger" >يېڭىلاش</b-button></span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="((subscribeMessageStocks.stocks ? subscribeMessageStocks.stocks.length : 0)/50) * 100" variant="danger"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">قېلىپ سانى</span>
                  <span class="kt-widget24__number">{{ subscribeMessageStocks.stocks ? subscribeMessageStocks.stocks.length : 0 }} دانە</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="stock in subscribeMessageStocks.stocks" :key="stock.id" sm="12" md="3">
          <portlet class="direction-rtl" body-class="flex-column" body-fluid head-size="sm" :title="stock.title">
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact" v-for="template_keyword in stock.template_keywords">
                        <span class="kt-widget__label">{{ template_keyword.title }}</span>
                        <span class="kt-widget__data direction-rtl">{{ template_keyword.example }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <p class="direction-rtl text-right" style="line-height: 1.8" v-html="stock.example.replace(/\r?\n/g, '<br />')"></p>-->
              <div class="kt-widget-item-container direction-ltr">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">ئىشلىتىلىش قېتىم سانى</span>
                        <span class="kt-widget__data direction-rtl">{{ stock.histories_count }} قېتىم</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> جەمئىي مۇشتەرى ئ‍ەزا </span>
                        <span class="kt-widget__data direction-rtl"> {{ stock.ready_user_count }} نەپەر </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> ئۇچۇر تاپشۇرۇپ ئالىدىغان ئ‍ەزا </span>
                        <span class="kt-widget__data direction-rtl"> {{ stock.ready_unique_user_count }} نەپەر </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="foot">
              <div class="direction-ltr">
                <b-button v-if="!stock.is_ban" :to="{name: sendPath, params: {id: stock.id}}" :disabled="!stock.ready_user_count" variant="primary" class="mr-2">ئۇچۇر يوللاش</b-button>
                <b-button :to="{name: historyPath, params: {id: stock.id}}" :disabled="!stock.histories_count" variant="success">يوللاش خاتىرىسى</b-button>
                <b-badge variant="warning" class="ml-3" v-if="stock.is_ban"> ئۆچۈرۈلگەن </b-badge>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="subscribeMessageStocks.stocks ? !subscribeMessageStocks.stocks.length : true" background="warning" title="ئۇچۇر تېپىلمىدى" :desc="emptyStockTips" >
        <template slot="action">
          <b-button href="https://mp.weixin.qq.com" target="_blank" class="btn-custom btn-bold btn-upper btn-font-sm btn-warning">قېلىپ قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import NoResult from "@v@/partials/content/NoResult";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import subscribeMessageStockMixin from "@m@/subscribeMessage.stock.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, NoResult },
    mixins: [ formBusyMixin, subscribeMessageStockMixin ],
    props: {
      belongs: {
        type: String,
        default: ()=> 'mp'
      }
    },
    created() {
      this.getSubscribeMessageStocks(this.belongs).then(response=>{
        console.log(response)
      });
    },
    computed: {
      sendPath(){
        if(this.belongs === 'mp')
          return 'subscribe_message.send';
        else if( this.belongs === 'h5' )
          return 'h5.subscribe_message.send';
        return '';
      },

      historyPath(){
        if(this.belongs === 'mp')
          return 'subscribe_message.history';
        else if( this.belongs === 'h5' )
          return 'h5.subscribe_message.history';
        return '';
      },
      emptyStockTips(){
        if( this.belongs === 'mp' )
          return `مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، ئەپچاق ئارقا بەتتىن مۇناسىۋەتلىك قېلىپ ئۇچۇرىنى تاللاپ قوشقاندىن كىيىن، ماسقەدەملەش كونۇپكىسىنى بېسىپ ماسقەدەملەڭ.`
        else if( this.belongs === 'h5' )
          return `مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، سالون ئارقا بەتتىن مۇناسىۋەتلىك قېلىپ ئۇچۇرىنى تاللاپ قوشقاندىن كىيىن، ماسقەدەملەش كونۇپكىسىنى بېسىپ ماسقەدەملەڭ.`
      }
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      if( this.belongs === 'mp' ){
        BreadCrumbs.addBreadCrumb({title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش', route: {name: 'subscribe_message.home'}});
      }else if( this.belongs === 'h5'){
        BreadCrumbs.addBreadCrumb({ title: "سالون مەركىزى" });
        BreadCrumbs.addBreadCrumb({title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش', route: {name: 'h5.subscribe_message.home'}});
      }
      BreadCrumbs.addBreadCrumb({ title: "باشقۇرۇش مەركىزى" });
    },
    data(){
      return {
        formBusy: false,
      };
    },
    methods: {
      onClickUpdateStock(){
        this.updateSubscribeMessageStock(this.belongs);
      }
    }
  }
</script>

<style>
</style>
