export const asset = (src)=>{
  return `${process.env.VUE_APP_API_BASE_URL}/storage/${src}`
}

function createMessage( { message, prefix = null }) {
  return `%c [${prefix ? prefix : 'DEBUG LOG'}] %c ${message} %c`;
}

function createSprint(logType = 'log') {
  switch ( logType ) {
    case "log":
      return ["background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
    case "warn":
      return ["background:#f37b1d ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
    case "error":
      return ["background:#fbbd08 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
  }
}

export const debugConsole = ( { message, ignoreDebug = false, logType = 'log', prefix = '' })=>{
  if ( ignoreDebug || process.env.VUE_APP_DEBUG ){
    switch ( logType ) {
      case "log":
        console.log(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
      case "warn":
        console.warn(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
      case "error":
        console.error(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
    }
  }
}
