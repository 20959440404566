import ApiService from "@c@/api.service";
import handleReject from "@c@/handleReject";

export const GET_SUBSCRIBE_MESSAGE_STOCK = 'getSubscribeMessageStock'; //
export const GET_SUBSCRIBE_MESSAGE_STOCKS = 'getSubscribeMessageStocks'; //
export const UPDATE_SUBSCRIBE_MESSAGE_STOCKS = 'updateSubscribeMessageStocks'; //
export const SEND_SUBSCRIBE_MESSAGE = 'sendSubscribeMessage'; //
export const GET_SUBSCRIBE_MESSAGE_HISTORY = 'getSubscribeMessageHistory'; //

// [NAVIGATION MUTATIONS]
export const SET_SUBSCRIBE_MESSAGE_STOCK = 'setSubscribeMessageStock'; //
export const SET_SUBSCRIBE_MESSAGE_STOCKS = 'setSubscribeMessageStocks'; //
export const SET_SUBSCRIBE_MESSAGE_STOCK_ERROR = 'setSubscribeMessageStockError'; //
export const SET_SUBSCRIBE_MESSAGE_HISTORY = 'setSubscribeMessageHistory'; //

const state = {
  subscribeMessageStock: {},
  subscribeMessageStocks: {},
  subscribeMessageStockError: null,
  subscribeMessageHistories: [],
  subscribeMessageHistoriesMeta: {},
};

const mutations = {
  [SET_SUBSCRIBE_MESSAGE_STOCK](state, payload){
    state.subscribeMessageStock = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_STOCKS](state, payload){
    state.subscribeMessageStocks = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_STOCK_ERROR](state, payload){
    state.subscribeMessageStockError = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_HISTORY](state, payload){
    state.subscribeMessageHistories = payload.data;
    state.subscribeMessageHistoriesMeta = payload.meta;
  },
};

const actions = {


  [GET_SUBSCRIBE_MESSAGE_STOCK](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`manager/subscribe_messages/`, id)
      .then(response=>{
        context.commit(GET_SUBSCRIBE_MESSAGE_STOCK, response.data);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        // handleReject(context, response, SET_USER_ERROR);
        // context.commit(SET_USER_ERROR, response.data.errors || response.data.message || response.data);
      });
    });
  },
  [GET_SUBSCRIBE_MESSAGE_STOCKS](context, belongs){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`manager/subscribe_messages?from=`+belongs)
      .then(response=>{
        context.commit(GET_SUBSCRIBE_MESSAGE_STOCKS, response.data);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR);
        context.commit(SET_SUBSCRIBE_MESSAGE_STOCK_ERROR, response.data.errors || response.data.message || response.data);
      });
    });
  },
  [UPDATE_SUBSCRIBE_MESSAGE_STOCKS](context, belongs='mp'){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`manager/subscribe_messages/update_templates`, { 'from':belongs })
      .then(response=>{
        context.commit(SET_SUBSCRIBE_MESSAGE_STOCKS, response.data);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR);
        reject(response);
      })
    })
  },
  [SEND_SUBSCRIBE_MESSAGE](context,  { id, formData = {} }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(   `manager/subscribe_messages/${id}/send`, formData)
      .then(response=>{
        context.commit(SET_SUBSCRIBE_MESSAGE_STOCKS, response.data);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR);
        reject(response);
      })
    })
  },
  [GET_SUBSCRIBE_MESSAGE_HISTORY](context, { id, page = 1 }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`manager/subscribe_messages/${id}/histories?page=${page}`)
      .then(response=>{
        context.commit(SET_SUBSCRIBE_MESSAGE_HISTORY, response.data);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR);
        context.commit(SET_SUBSCRIBE_MESSAGE_STOCK_ERROR, response.data.errors || response.data.message || response.data);
      });
    });
  },

};

const getters = {
  subscribeMessageStock: state=> state.subscribeMessageStock,
  subscribeMessageStocks: state=> state.subscribeMessageStocks,
  subscribeMessageStockError: state=> state.subscribeMessageStockError,
  subscribeMessageHistories: state=> state.subscribeMessageHistories,
  subscribeMessageHistoriesMeta: state=> state.subscribeMessageHistoriesMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
