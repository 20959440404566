import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_SUBSCRIBE_MESSAGE_STOCK,
  GET_SUBSCRIBE_MESSAGE_STOCKS,
  UPDATE_SUBSCRIBE_MESSAGE_STOCKS,
  SEND_SUBSCRIBE_MESSAGE,
  GET_SUBSCRIBE_MESSAGE_HISTORY, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR,
} from "@/store/modules/subscribeMessage.stock.module";
import handleReject from "@c@/handleReject";
import ApiService from "@c@/api.service";
import { SET_ADVERTS_ERROR } from "@/store/types";

export default {
  computed: {
    ...mapGetters(['subscribeMessageStock', 'subscribeMessageStocks', 'subscribeMessageStockError', 'subscribeMessageHistories', 'subscribeMessageHistoriesMeta'])
  },
  methods: {
    getSubscribeMessageStock(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_STOCK, id),
      actions
      );
    },
    getSubscribeMessageStocks(belongs = 'mp'){

      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`manager`,`subscribe_messages?from=`+belongs)
        .then(response=>{
          this.hideBusy();
          this.$store.commit(GET_SUBSCRIBE_MESSAGE_STOCKS, response.data);
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR);
          reject(response);
        })
      });
      // this.showBusy();
      // return new Promise((resolve, reject) => {
      //   this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_STOCKS, belongs);
      //   // .then(response=>{
      //   //   this.hideBusy();
      //   //   resolve(response);
      //   // })
      //   // .catch(response=>{
      //   //   this.hideBusy();
      //   //   handleReject(this.$store, response, SET_DOWNLOAD_ERROR);
      //   //   reject(response);
      //   // })
      // });

    },
    updateSubscribeMessageStock(belongs = 'mp', actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_SUBSCRIBE_MESSAGE_STOCKS, belongs),
      actions
      );
    },
    sendSubscribeMessage(id, formData, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(SEND_SUBSCRIBE_MESSAGE, {id, formData}),
      actions
      );
    },
    getSubscribeMessageHistory(id, page = 1, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_HISTORY, { id, page }),
      actions
      );
    },
  }
}
